<template>
  <div class="app-box">
    <!-- <pdf ref="pdf" v-for="i in numPages" :key="i" :src="url" :page="i"></pdf> -->
    <van-image
      width="100%"
      v-for="(item, index) in imgUrl"
      :key="index"
      :src="item"
      @click="OperClick(index)"
    />
    <div class="moeiae">
     <div class="padoer">
        <van-checkbox class="tilexb" v-model="checked"
        >同意签约协议合同</van-checkbox
      >
      <van-button type="info" block @click="netx">下一步</van-button>
     </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      checked: false,
       imgUrl: [
        'https://wwy-apps.oss-cn-shenzhen.aliyuncs.com/crm/atorCenter1.jpg',
        'https://wwy-apps.oss-cn-shenzhen.aliyuncs.com/crm/atorCenter2.jpg',
        'https://wwy-apps.oss-cn-shenzhen.aliyuncs.com/crm/atorCenter3.jpg',
        'https://wwy-apps.oss-cn-shenzhen.aliyuncs.com/crm/atorCenter4.jpg',
        'https://wwy-apps.oss-cn-shenzhen.aliyuncs.com/crm/atorCenter5.jpg',
        'https://wwy-apps.oss-cn-shenzhen.aliyuncs.com/crm/atorCenter6.jpg'
      ],
      // url: "https://wwy-apps.oss-cn-shenzhen.aliyuncs.com/crm/atorCenter.pdf",
      numPages: null, // pdf 总页数
    };
  },
  mounted() {
    // this.getNumPages();
  },
  methods: {
      OperClick(index) {
      ImagePreview({
        images:this.imgUrl,
        startPosition: index,
      });
    },
    netx() {
      if (!this.checked) {
        Toast.fail("请同意签约合同");
        return;
      }
      this.$router.push("/FilLinformation");
    },
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.url);
      loadingTask.promise
        .then((pdf) => {
          this.numPages = pdf.numPages;
        })
        .catch((err) => {
          console.error("pdf 加载失败", err);
        });
    },
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  /* background-color: #edf2f5ff; */
  box-sizing: border-box;
  overflow: hidden;
  padding: 0.4rem 0.4rem;
  box-sizing: border-box;
  padding-bottom: 2rem;
}
.text {
  font-size: 0.45rem;
  height: 82vh;
  overflow: hidden;
  overflow-y: scroll;
}
.text::-webkit-scrollbar {
  display: none;
}
.moeiae {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.3rem 0;
  font-size: 0.45rem;
  background: #fff;
}
.tilexb {
  padding-bottom: 0.3rem;
}
.padoer{
  padding: 0 .3rem;
  box-sizing: border-box;
}
::v-deep .van-loading{
  margin: auto;
}
</style>